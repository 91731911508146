<template>
  <div id="research">请到电脑端填写调查！</div>
</template>
<script>
export default {
  name: "Research",
  data() {
    return {};
  },
};
</script>
<style scoped>
#research {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  white-space: nowrap;
}
</style>